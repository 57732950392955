<template>
    <router-view />
</template>

<script>

export default {
  name: 'Task'
}
</script>

<style scoped>

</style>
